@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  /*************Contact Us Acoordition********************/
  .wrapper-contactus {
    @apply flex justify-center items-center mb-2;
  }
  .accordition-contactus {
    @apply w-8/12 max-md:w-full;
  }
  .item-contactus {
    @apply mb-1 px-7 pt-3 pb-4 cursor-pointer border-b-[1px] border-b-[#1C2347];
  }
  .item-contactus h5 {
    @apply font-['lato'] text-[18px] font-semibold text-left max-md:text-[16px];
  }
  .item-contactus div:last-child {
    @apply font-['lato'] text-white text-[14px];
  }
  .tittle-contactus {
    @apply font-semibold flex justify-between items-center;
  }
  .content-contactus {
    @apply text-white font-['lato'] font-[300] text-left text-left ml-4 overflow-hidden transition-all max-h-0 text-[18px];
  }
  .content-contactus.show {
    @apply text-white font-['lato'] overflow-hidden transition-all h-auto max-h-96 py-1;
  }
  /******************************************/
  /*********Flyout and Flyin Animations ************/

  @keyframes flyout {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(20px);
      opacity: 0;
    }
  }
  @keyframes flyin {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .flyout {
    animation: flyout 0.5s forwards;
  }
  .flyin {
    animation: flyin 0.5s forwards;
  }
  .group-hover .flyout-hover {
    animation: flyout 0.5s forwards;
  }
  .group:hover .flyin-hover {
    animation: flyin 0.5s forwards;
  }
  /* Message Box Toast */
  .message-container {
    @apply text-center fixed z-[9999999999] top-5 left-0 w-full max-md:top-2.5;
  }
  .message-container > div {
    @apply m-0 text-left bg-white min-w-message max-w-message inline-block py-5 px-4 rounded-md overflow-hidden shadow-message max-md:py-2.5 max-md:px-1.5 max-md:shadow-message2;
  }
  .message-container > div > div {
    @apply grid grid-cols-[55px_1fr_80px] items-center max-md:grid-cols-[45px_1fr_60px];
  }
  .message-container > div > div > div {
    @apply border-r-[1px] border-[#777777] pr-5 max-md:pr-2.5;
  }
  .message-container img {
    @apply w-10;
  }
  .message-container h4,
  .message-container h5,
  .message-container p {
    @apply m-0;
  }
  .message-container h4 {
    @apply font-medium text-xl mb-1;
  }
  .message-container h5 {
    @apply text-[#777777] text-base;
  }
  .message-container p {
    @apply text-[#ABABAB] text-base cursor-pointer text-center pl-4 max-md:pl-0;
  }
  .message-container p:hover {
    @apply text-[#777777];
  }
  .error-message-box {
    @apply border-l-8 border-[#FE2343] text-[#FE2343];
  }
  .success-message-box {
    @apply border-l-8 border-[#24B056] text-[#24B056];
  }
}
